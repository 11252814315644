<template>
  <div>
    <div v-if="isLoading" class="text-center py-4">
      <BaseProgressSpinner />
    </div>

    <div v-else-if="!course">
      <BaseButton label="uc.dashboard.course.buyCourse" @click="pushToBuyCourse" />
    </div>

    <BaseRow v-else :no-gutter="true">
      <BaseCol cols="12" lg="3">
        <CourseThumbnail :course="course" />
      </BaseCol>

      <BaseCol cols="12" lg="9" class="px-6">
        <BaseHeadline size="3" fake="3" class="mb-3" noMargin>
          {{ course.title }}
        </BaseHeadline>

        <CourseProgressBar :course="course" class="mb-3" />

        <CourseTopics :course-topics="course.courseTopics" />

        <div class="flex justify-content-between align-items-end">
          <div>
            <p class="text-500 mt-3">
              {{ getTeacherName(course.teacher) }}
            </p>

            <p class="text-500 mt-2">
              {{
                $t("uc.dashboard.course.completedChapters", {
                  count: course.courseChaptersCount,
                  completed: course.completedCourseChaptersCount,
                })
              }}
            </p>
          </div>
          <div>
            <BaseButton label="uc.dashboard.course.button" @click="pushToCourse(course.id)" />
          </div>
        </div>
      </BaseCol>
    </BaseRow>
  </div>
</template>

<script>
import useApi from "@use/useApi";
import { ref } from "vue";
import CourseThumbnail from "@/modules/user-course-uc/components/CourseThumbnail/CourseThumbnail";
import CourseTopics from "@/modules/haw-components-hac/Base/CourseTopics";
import { getTeacherName } from "@/modules/user-course-uc/helper/GetTeacherName";
import { getPriceLabel } from "@/modules/user-course-uc/helper/GetPriceLabel";
import { useRouter } from "vue-router";
import CourseProgressBar from "@/modules/haw-components-hac/Base/CourseProgressBar";

export default {
  name: "LastCoursePreview",
  components: { CourseProgressBar, CourseTopics, CourseThumbnail },
  setup() {
    const router = useRouter();

    const course = ref({
      teacher: {},
    });

    const { isLoading, docs } = useApi({
      module: "uc",
      method: "showLatestCourse",
      onMount: true,
      onSuccess: () => {
        course.value = docs.value.data;
      },
    });

    const pushToCourse = async (courseId) => {
      try {
        await router.push({
          name: "MyCoursesShow",
          params: {
            courseId,
          },
        });
      } catch {
        return;
      }
    };

    const pushToBuyCourse = async () => {
      try {
        await router.push({
          name: "Courses",
        });
      } catch {
        return;
      }
    };

    return {
      isLoading,
      course,
      getTeacherName,
      getPriceLabel,
      pushToCourse,
      pushToBuyCourse,
    };
  },
};
</script>
