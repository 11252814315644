<template>
  <HAWPageContent :breadcrumb="false">
    <BaseContainer fluid class="max-w-1024">
      <BaseCard class="p-3">
        <BaseRow>
          <BaseCol cols="12">
            <BaseHeadline size="1" fake="4" :noMargin="true" class="mb-2">
              {{ $t("uc.dashboard.welcome.title") }}
            </BaseHeadline>
          </BaseCol>
          <BaseCol cols="12">
            <BaseParagraph :noMargin="true">
              {{ $t("uc.dashboard.welcome.label") }}
            </BaseParagraph>
          </BaseCol>
        </BaseRow>
      </BaseCard>
      <BaseCard class="p-3 mt-5">
        <BaseRow>
          <BaseCol cols="12">
            <BaseHeadline size="1" fake="4" :noMargin="true" class="mb-2">
              {{ $t("uc.dashboard.course.title") }}
            </BaseHeadline>
          </BaseCol>
          <BaseCol cols="12">
            <BaseParagraph :noMargin="true">
              {{ $t("uc.dashboard.course.label") }}
            </BaseParagraph>
          </BaseCol>
          <BaseCol cols="12">
            <LastCoursePreview class="mt-3" />
          </BaseCol>
        </BaseRow>
      </BaseCard>
    </BaseContainer>
  </HAWPageContent>
</template>

<script>
import HAWPageContent from "@/modules/haw-components-hac/Base/HAWPageContent";
import LastCoursePreview from "../../../components/LastCoursePreview";

export default {
  components: {
    LastCoursePreview,
    HAWPageContent,
  },
};
</script>
